import LoginForm from '@theme/components/user/LoginForm/'
import Modal from '@theme/components/utils/Modal/'

export default {
  components: {
    LoginForm,
    Modal,
  },
  mounted() {
    this.$nuxt.$on('openLoginModal', this.openModal)
  },
  beforeDestroy() {
    this.$nuxt.$off('openLoginModal', this.openModal)
  },
  methods: {
    openModal() {
      this.$nextTick(() => {
        this.$refs.loginModal.show()
      })
    },
  },
}
