var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('portal',{attrs:{"to":"modal"}},[(_vm.open)?_c('div',{ref:"Modal",staticClass:"Modal",class:[
      _vm.modalClass,
      {
        'Modal--inactive': _vm.inactive,
        'Modal--open': _vm.open,
        'Modal--fullscreen': _vm.fullscreen,
      }
    ]},[_c('div',{staticClass:"Modal__wrapper",class:{
        'Modal__wrapper--open': _vm.open,
        'Modal__wrapper--inMiddleOfScreen': _vm.inMiddleOfScreen
      },attrs:{"aria-labelledby":_vm.ariaLabelledBy,"role":"dialog"}},[_c('div',{staticClass:"Modal__window",class:[
          _vm.fullscreen ? 'Modal__window--fullscreen' : '',
          _vm.windowMaxWidth ? 'Modal__window--size-max-' + _vm.windowMaxWidth : ''
        ]},[_vm._t("window",function(){return [_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],staticClass:"Modal_contentWrapper"},[(_vm.showCloseIcon === true)?_vm._t("close",function(){return [_c('button',{staticClass:"Modal__close btn p-0",attrs:{"aria-label":_vm.$t('Modal.close.ariaLabel'),"tabindex":"0"},on:{"click":_vm.hide,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.hide.apply(null, arguments)}}},[_c('svgicon',{staticClass:"Modal__closeIcon",attrs:{"icon":require('@icon/cross.svg?raw')}})],1)]},{"hide":_vm.hide}):_vm._e(),_c('div',{staticClass:"Modal__content"},[_vm._t("content",null,{"hide":_vm.hide})],2)],2)]}),_c('div',{staticClass:"Modal__afterContent",class:{'Modal__afterContent--empty': !_vm.$slots['afterContent']},on:{"click":function($event){$event.stopPropagation();}}},[_vm._t("afterContent",null,{"hide":_vm.hide})],2)],2)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }