import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },
  props: {
    ariaLabelledBy: String,
    fullscreen: {
      type: Boolean,
      default: false,
    },
    inMiddleOfScreen: {
      type: Boolean,
      default: true,
    },
    modalClass: String,
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
    windowMaxWidth: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modal: null,
      open: false,
      inactive: false,
      frozen: true,
    }
  },
  mounted() {
    this.$nuxt.$on('closeAllModals', this.closeModal)
  },
  beforeDestroy() {
    this.clean()
    this.$nuxt.$off('closeAllModals', this.closeModal)
  },
  methods: {
    clickOutside(event) {
      // Bug hack for v-click-outside - Do not react to outside click if frozen
      if (!this.frozen) {
        this.hide()
      }
    },
    closeModal() {
      this.open = false
    },
    keydown(event) {
      if (event.keyCode === 27) {
        this.hide()
      }
    },
    show() {
      const oThis = this

      this.$nuxt.$emit('Modal.show')
      this.$nuxt.$on('Modal.hide', this.hideWindow)
      this.$nuxt.$on('Modal.show', this.addWindowToStack)
      this.open = true
      this.inactive = false
      document.body.classList.add('---modalOpen')
      document.addEventListener('keyup', this.keydown)
      this.$nextTick(() => {
        this.$emit('show')
        this.$nextTick(() => {
          this.modal = this.$refs.Modal
          disableBodyScroll(this.modal)
          setTimeout(function() {
            oThis.frozen = false
          }, 300)
        })
      })
    },
    hide() {
      this.$nuxt.$emit('Modal.hide')
    },
    clean() {
      this.$nuxt.$off('Modal.show', this.addWindowToStack)
      this.$nuxt.$off('Modal.hide', this.hideWindow)
      enableBodyScroll(this.modal)
      document.body.classList.remove('---modalOpen')
      document.removeEventListener('keyup', this.keydown)
    },
    hideWindow() {
      this.open = false
      this.inactive = false
      this.frozen = true
      this.clean()
      this.$nextTick(() => {
        this.$emit('hide')
      })
    },
    addWindowToStack() {
      this.inactive = true
    },
  },
}
